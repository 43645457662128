import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';

import { NoteForm } from '@/components/ui/forms';
import { ActionsForm } from '@/components/ui/modals/DetailCandidacyModal/types';

import useMainForm from './useMainForm';

import type { ITabProps } from '@/components/ui/modals/DetailCandidacyModal/components/types';

const MainForm = ({ candidacy, onChangeActionsForm }: ITabProps) => {
  const { hasFinalist, userNote, handleCreateNote, handleCreateQuestion, handleCreateRating } =
    useMainForm({
      candidacy,
    });

  return (
    <>
      <Grid item sm={12}>
        <NoteForm
          value=""
          label="Ask question for more details"
          title="Ask for more info"
          onSubmit={handleCreateQuestion}
        />
      </Grid>

      <Grid item sm={12}>
        <NoteForm
          value={userNote?.note || ''}
          label="Save note for this applicant"
          title="Personal Note"
          onSubmit={handleCreateNote}
          btnText="Save"
        />
      </Grid>
      <Grid item sm={12} mt={3}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sm={4} display="flex" justifyContent={{ xs: 'center' }}>
            <Button
              variant="contained"
              onClick={onChangeActionsForm(ActionsForm.decline)}
              color="error"
              disabled={hasFinalist}
            >
              Decline
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} display="flex" justifyContent="center">
            <Rating
              precision={0.5}
              value={(candidacy?.rating ?? 0) / 2}
              onChange={(e, value) => handleCreateRating(value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            justifyContent={{ sm: 'flex-end', xs: 'center' }}
          >
            <Button
              variant="contained"
              color="success"
              disabled={hasFinalist || !!candidacy?.refusal?.length}
              onClick={onChangeActionsForm(ActionsForm.talk)}
            >
              Let`s talk
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MainForm;
