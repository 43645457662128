export const listCandidacy: IListType<ICandidacyResponse>[] = [
  {
    label: 'MOQ',
    format: (value: ICandidacyResponse) => value?.moq ?? null,
  },
  {
    label: 'Price',
    format: (value: ICandidacyResponse) => value?.exw_price ?? null,
  },
  {
    label: 'Boxes per Isopallets',
    format: (value: ICandidacyResponse) => value.boxes_x_isopallet,
  },
];

import { CategoryElement } from '@/components/ui/lists/components';
import { editorialUtil } from '@/utils';

import type { IListType } from '@/components/ui/lists/ListProps/types';
import type { ICandidacyResponse, IGoodResponse } from '@/dto';
import type { TFunction } from 'i18next';

export const list = (t: TFunction, shouldTrackImage = false): IListType<IGoodResponse>[] => [
  {
    label: 'Good Class',
    format: (value: IGoodResponse) => value.GoodClass.name,
  },
  {
    label: 'Preservation Mode',
    format: (value: IGoodResponse) =>
      value.GoodClass.preservation ? t(value.GoodClass.preservation) : '',
  },

  {
    label: 'Features & Certification',
    format: (value: IGoodResponse) =>
      value.goodFeatureCertifications.map((item) => item.name).join(', '),
  },
  {
    label: 'Sales formats',
    format: (value: IGoodResponse) =>
      value.formats
        .map(
          (format) =>
            `${format.cuPerSellingPack} x ${format.cuMesureValue}${t(format.cuMesureUnit)}`,
        )
        .join(', '),
  },
  {
    label: 'Shelf life',
    format: (value: IGoodResponse) => value.shelfLifeLength,
  },
  {
    label: 'Product Categories',
    format: (value: IGoodResponse) => (
      <CategoryElement
        shouldTrackImage={shouldTrackImage}
        editorial={editorialUtil.parse(value.GoodClass.editorialSections)}
      />
    ),
    rowSx: { display: 'flex', alignItems: 'center', mb: 1 },
  },
];
