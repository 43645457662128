import { ImageLoader, ListProps } from '@/components';
import { listOfRows } from '@/components/ui/modals/DetailCandidacyModal/components/BrandTab/constants';

import styles from '../styles.module.scss';

import { list } from './constants';

import type { IPdfPageProps } from '../types';

const BrandPage = ({ candidacy }: IPdfPageProps) => {
  return (
    <div className={[styles.page, 'page'].join(' ')}>
      <div className={styles.row}>
        <div className={[styles.col12, styles.header].join(' ')}>
          <h1 className={[styles.textCenter, styles.textMute, styles['bt-3']].join(' ')}>Brand</h1>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col2}>
          <ImageLoader
            className={styles.image}
            alt={candidacy?.Brand?.name}
            src={candidacy?.Brand?.logoMedia?.mediumThumbUrl}
          />
        </div>
        <div className={styles.col10}>
          <h1 className={styles.title}>{candidacy?.Brand?.name}</h1>
          <div className={[styles.list, styles['mt-2']].join(' ')}>
            <ListProps dataSource={candidacy} list={list} twoColumn />
            <ListProps dataSource={candidacy} list={listOfRows(true)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPage;
