import { ImageLoader, ListProps } from '@/components';
import {
  list,
  listOfRows,
} from '@/components/ui/modals/DetailCandidacyModal/components/CompanyTab/constants';

import styles from '../styles.module.scss';

import type { IPdfPageProps } from '../types';

const CompanyPage = ({ candidacy }: IPdfPageProps) => {
  return (
    <div className={[styles.page, 'page'].join(' ')}>
      <div className={styles.row}>
        <div className={[styles.col12, styles.header].join(' ')}>
          <h1 className={[styles.textCenter, styles.textMute, styles['bt-3']].join(' ')}>
            Company
          </h1>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col2}>
          <ImageLoader
            className={styles.image}
            alt={candidacy?.Company?.name}
            src={candidacy?.Company?.logoMedia?.mediumThumbUrl}
          />
        </div>
        <div className={styles.col10}>
          <h1 className={styles.title}>{candidacy.Company.name}</h1>
          <div className={styles.subTitle}>
            {candidacy?.Company?.yearEstablished && (
              <span className={styles.textMute}>Since {candidacy?.Company?.yearEstablished}</span>
            )}
            {' / '}
            {candidacy?.Company?.ownership && (
              <span className={[styles.textMute].join(' ')}>
                {candidacy?.Company?.ownership.replace('_', ' ').toLowerCase()}
              </span>
            )}
          </div>
          <div className={[styles.col12, styles.list].join(' ')}>
            <ListProps dataSource={candidacy} list={list} twoColumn />
          </div>
        </div>
      </div>

      <div className={styles.row}>
        <div className={[styles.col12, styles.list].join(' ')}>
          <ListProps dataSource={candidacy} list={listOfRows(true)} />
        </div>
      </div>

      {candidacy?.Company?.descriptionLong && (
        <div className={styles.row}>
          <div className={styles.col12}>
            <h4>Description:</h4>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: candidacy.Company.descriptionLong }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyPage;
